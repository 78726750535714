import './index.css'
import './App.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import React, { Suspense } from 'react'

import App from './App'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'context/ThemeContext'
import store from './store/store'
import reportWebVitals from './reportWebVitals'
import { SettingsProvider } from 'context/SettingsContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading... </div>}>
      <ThemeProvider>
        <SettingsProvider initialSetting="on">
          <Provider store={store}>
            <App />
          </Provider>
        </SettingsProvider>
      </ThemeProvider>
    </Suspense>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
