import { createContext, useEffect, useState } from 'react'

const getInitialTheme = () => {
  if (typeof window !== 'undefined' &&
      window.localStorage) {

    const storedPrefs =
      window.localStorage.getItem('color-theme')
    if (typeof storedPrefs === 'string') {
      return storedPrefs
    }

    const userMedia =
      window.matchMedia('(prefers-color-scheme: dark)')
    if (userMedia.matches) {
      return 'dark'
    }
  }

  // If you want to use light theme as the default,
  // return "light" instead
  return 'dark'
}

export const ThemeContext = createContext()

export const ThemeProvider = ({
  initialTheme,
  children }) => {
  const [theme, setTheme] = useState(getInitialTheme)

  const rawSetTheme = theme => {
    const root = window.document.documentElement
    const isDark = theme === 'dark'

    root.classList.remove(isDark ? 'light' : 'dark')
    root.classList.add(theme)

    localStorage.setItem('color-theme', theme)
  }

  if (initialTheme) {
    rawSetTheme(initialTheme)
  }

  useEffect(
    () => {
        rawSetTheme(theme);
      },
      [theme]
    )

  return (
    <ThemeContext.Provider value={{ theme, setTheme, postMessage }}>
      {children}
    </ThemeContext.Provider>
  )
}