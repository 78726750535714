export const GET_PUZZLE_DATA = 'GET_PUZZLE_DATA';
export const GET_START_PUZZLE = 'GET_START_PUZZLE';
export const PUT_COMPLETE_PUZZLE = 'PUT_COMPLETE_PUZZLE';
export const GET_RECALCULATE_PUZZLE = 'GET_RECALCULATE_PUZZLE';
export const GET_PUZZLE_RESULT = 'GET_PUZZLE_RESULT';
export const PUT_PUZZLE_STORY = 'PUT_PUZZLE_STORY';
export const GET_USER_STATS = 'GET_USER_STATS';

export const UPDATE_PUZZLE_DIRECTION = 'UPDATE_PUZZLE_DIRECTION';
export const UPDATE_PUZZLE_DATE = 'UPDATE_PUZZLE_DATE';
export const UPDATE_PUZZLE_STATUS = 'UPDATE_PUZZLE_STATUS';
export const UPDATE_PUZZLE_STATS_SHOW = 'UPDATE_PUZZLE_STATS_SHOW';
export const STORE_USER_PATH = 'STORE_USER_PATH';
