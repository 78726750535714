import { handleActions } from 'redux-actions';
import { requestSuccess, requestFail } from '../../api/request';
import * as CONSTANTS from './constants';

const getInitialState = () => {
  return {
    direction: 'right',
    data: {},
    twoDaysData: {},
    status: '',
    user_path: [],
    result: {},
    stats: {},
    error: null,
    story: null,
    isLoadingStory: false,
    gameOption: 'today',
    puzzleStatus: 'NOT_STARTED',
    showStats: false,
  };
};

export default handleActions(
  {
    [CONSTANTS.GET_PUZZLE_DATA]: (state) => ({
      ...state,
      status: 'PENDING',
    }),
    [requestSuccess(CONSTANTS.GET_PUZZLE_DATA)]: (state, { payload }) => ({
      ...state,
      status: 'SUCCESS',
      data: {
        puzzle_number: payload?.puzzle_number,
        id: payload?.today?.id,
        start_word: payload?.today?.start_word,
        target_word: payload?.today?.target_word,
      },
      twoDaysData: payload,
      gameOption: 'today',
    }),
    [requestFail(CONSTANTS.GET_PUZZLE_DATA)]: (state, { payload }) => ({
      ...state,
      status: 'FAILED',
      error: payload,
    }),


    [CONSTANTS.GET_START_PUZZLE]: (state) => ({
      ...state,
      status: 'PENDING',
    }),
    [requestSuccess(CONSTANTS.GET_START_PUZZLE)]: (state) => ({
      ...state,
      status: 'SUCCESS',
      puzzleStatus: 'PLAYING'
    }),
    [requestFail(CONSTANTS.GET_START_PUZZLE)]: (state) => ({
      ...state,
      status: 'FAILED',
    }),

    [CONSTANTS.PUT_COMPLETE_PUZZLE]: (state) => ({
      ...state,
      status: 'PENDING',
    }),
    [requestSuccess(CONSTANTS.PUT_COMPLETE_PUZZLE)]: (state) => ({
      ...state,
      status: 'SUCCESS',
      puzzleStatus: 'COMPLETED'
    }),
    [requestFail(CONSTANTS.PUT_COMPLETE_PUZZLE)]: (state) => ({
      ...state,
      status: 'FAILED',
    }),

    [CONSTANTS.GET_RECALCULATE_PUZZLE]: (state) => ({
      ...state,
      status: 'PENDING'
    }),
    [requestSuccess(CONSTANTS.GET_RECALCULATE_PUZZLE)]: (state) => ({
      ...state,
      status: 'SUCCESS',
      puzzleStatus: 'COMPLETED'
    }),
    [requestFail(CONSTANTS.GET_RECALCULATE_PUZZLE)]: (state, { payload }) => ({
      ...state,
      status: 'FAILED',
      error: payload,
    }),

    [CONSTANTS.GET_PUZZLE_RESULT]: (state) => ({
      ...state,
      status: 'PENDING',
    }),
    [requestSuccess(CONSTANTS.GET_PUZZLE_RESULT)]: (state, { payload }) => ({
      ...state,
      status: 'SUCCESS',
      result: payload,
    }),
    [requestFail(CONSTANTS.GET_PUZZLE_RESULT)]: (state, { payload }) => ({
      ...state,
      status: 'FAILED',
      result: payload,
    }),

    [CONSTANTS.GET_USER_STATS]: (state) => ({
      ...state,
      status: 'PENDING',
    }),
    [requestSuccess(CONSTANTS.GET_USER_STATS)]: (state, { payload }) => ({
      ...state,
      status: 'SUCCESS',
      stats: payload.result,
    }),
    [requestFail(CONSTANTS.GET_USER_STATS)]: (state, { payload }) => ({
      ...state,
      status: 'FAILED',
      error: payload,
    }),

    [CONSTANTS.PUT_PUZZLE_STORY]: (state) => ({
      ...state,
      isLoadingStory: true,
    }),
    [requestSuccess(CONSTANTS.PUT_PUZZLE_STORY)]: (state, { payload }) => ({
      ...state,
      isLoadingStory: false,
      story: payload.result,
    }),
    [requestFail(CONSTANTS.PUT_PUZZLE_STORY)]: (state, { payload }) => ({
      ...state,
      isLoadingStory: false,
      error: payload,
    }),


    [CONSTANTS.UPDATE_PUZZLE_DIRECTION]: (state) => ({
      ...state,
      data: {
        ...state.data,
        start_word: state.data?.target_word,
        target_word: state.data?.start_word,
      },
      direction: state.direction === 'right' ? 'reversed' : 'right',
    }),

    [CONSTANTS.UPDATE_PUZZLE_DATE]: (state, { payload }) => ({
      ...state,
      data: {
        puzzle_number: state.twoDaysData?.puzzle_number,
        id: state.twoDaysData?.[payload]?.id,
        start_word: state.twoDaysData?.[payload]?.start_word,
        target_word: state.twoDaysData?.[payload]?.target_word,
      },
      gameOption: payload,
    }),

    [CONSTANTS.UPDATE_PUZZLE_STATUS]: (state, { payload }) => ({
      ...state,
      puzzleStatus: payload.status,
    }),

    [CONSTANTS.UPDATE_PUZZLE_STATS_SHOW]: (state, { payload }) => ({
      ...state,
      showStats: payload.stats,
    }),

    [CONSTANTS.STORE_USER_PATH]: (state, { payload }) => ({
      ...state,
      user_path: payload?.user_path,
    }),
  },

  getInitialState()
);
