// WITH LAZY LOADING! :(

import * as React from 'react'

import { Route, Routes } from 'react-router-dom'

const Admin = React.lazy(() => import('./pages/Admin')) // <-- With Lazy loading!
const MainComponent = React.lazy(() => import('pages/MainComponent')) // <-- With Lazy loading!

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<MainComponent />} />
      <Route path="admin" element={<Admin />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  )
}

const NoMatch = () => {
  return <p>Nothing here: 404!</p>
}

export default App
